import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IonIcon, IonImg, IonLabel, IonTabButton } from '@ionic/react';
import {
    chatbubbleEllipses,
    home,
    notifications,
    searchOutline,
} from 'ionicons/icons';
import styles from './NavBar.module.css';
import { useQuery } from '@apollo/react-hooks';
import {
    GetNotificationsByUser,
    GetNotificationsByUserVariables,
} from 'GraphQL/__generated__/GetNotificationsByUser';
import { GET_NOTIFICATIONS_BY_USER } from 'GraphQL/Notification/GET_NOTIFICATIONS_BY_USER/GET_NOTIFICATIONS_BY_USER';
import { useAuth } from 'context/firebase/authContext';
import { User_Data } from 'GraphQL/Profile/GetUserByUID/getUserByUID';
import notifications_unreadsvg from './notifications_unread.svg';
import ChatRoomById from 'pages/Chat/ChatRoom/ChatRoomById';
import { firestore } from 'context/firebase/firebase';
import { useLocation } from '@reach/router';
import { FirebaseHelper } from 'helpers/FirebaseHelper';
import firebase from 'firebase';

var notifyNumber: number = 0;

interface Messages {
    id: string;
    sentAt: any;
    message: string;
    sender: string;
    senderName: string;
    seen: boolean;
}

const buttons = (path: string, messagesCount: any) => [
    <IonTabButton
        key='home'
        href='/home'
        selected={!!path.match(/^\/(?:home)?\/?$/)}
    >
        <IonIcon icon={home} />
        <IonLabel>Home</IonLabel>
    </IonTabButton>,

    <IonTabButton
        key='ops'
        href='/notifications'
        selected={!!path.match(/^\/notifications.*/)}
        className='notification'
    >
        {notifyNumber <= 0 ? (
            ''
        ) : (
            <span className={styles.unreadCount}>
                {notifyNumber >= 100 ? '99+' : notifyNumber}
            </span>
        )}
        <IonIcon icon={notifications}></IonIcon>
        <IonLabel>Notifications</IonLabel>
    </IonTabButton>,

    <IonTabButton
        key='search'
        href='/search'
        selected={!!path.match(/^\/search.*/)}
    >
        <IonIcon icon={searchOutline} />
        <IonLabel>Search</IonLabel>
    </IonTabButton>,

    <IonTabButton key='msgs' href='/chat' selected={!!path.match(/^\/chat.*/)}>
        <div key='msgcnt'>
            {messagesCount <= 0 ? (
                ''
            ) : (
                <span className={styles.unreadCount}>
                    {messagesCount >= 100 ? '99+' : messagesCount}
                </span>
            )}
        </div>
        <IonIcon icon={chatbubbleEllipses} />
        <IonLabel>Messages</IonLabel>
    </IonTabButton>,
];

const NavBar: React.FC = ({ children }: { children?: React.ReactNode }) => {
    const { currentUser } = useAuth();
    var isLoadingMessages = true;
    const [messageCount, setMessageCount] = useState<number>(0);
    console.debug('ello');

    const {
        location: { pathname: path },
    } = useHistory();
    const { data, loading } = useQuery<
        GetNotificationsByUser, // query return return type (shape of data)
        GetNotificationsByUserVariables // query request type (shape of variables)
    >(GET_NOTIFICATIONS_BY_USER, {
        variables: { uid: currentUser.uid },
        pollInterval: 5000,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        var messageNumber = 0;
        const unsubscribe = firestore
            .collection('chatrooms')
            .where(`users.${currentUser.uid}.id`, '==', currentUser.uid)
            .onSnapshot((iQuery) => {
                if (iQuery.docs.length > 0) {
                    for (let i = 0; i < iQuery.docs.length; i++) {
                        // Checking for new messages after page has loaded
                        firestore
                            .collection('chatrooms')
                            .doc(iQuery.docs[i].id)
                            .collection('messages')
                            .where(`seen`, '==', false)
                            .orderBy(`sentAt`, `desc`)
                            .limit(1)
                            .onSnapshot(
                                (jQuery) => {
                                    jQuery.forEach((doc) => {
                                        const { id } = doc;
                                        const msgObj = { ...doc.data(), id };
                                        if (
                                            !isLoadingMessages &&
                                            (msgObj as Messages).sender !==
                                                currentUser.uid
                                        ) {
                                            messageNumber++;
                                        }

                                        setMessageCount(messageNumber);
                                    });
                                },
                                (err) => console.error(err)
                            );

                        // Checking for new messages during page loading
                        firestore
                            .collection('chatrooms')
                            .doc(iQuery.docs[i].id)
                            .collection('messages')
                            .where(`seen`, '==', false)
                            .onSnapshot(
                                (jQuery) => {
                                    if (isLoadingMessages) {
                                        jQuery.forEach((doc) => {
                                            const { id } = doc;
                                            const msgObj = {
                                                ...doc.data(),
                                                id,
                                            };
                                            if (
                                                (msgObj as Messages).sender !=
                                                currentUser.uid
                                            ) {
                                                messageNumber++;
                                            }
                                        });
                                    }
                                    setMessageCount(messageNumber);
                                    if (messageNumber > 0)
                                        isLoadingMessages = false;
                                },
                                (err) => console.error(err)
                            );
                    }
                }
            });
        return () => unsubscribe();
    }, []);

    if (!loading) {
        notifyNumber = 0;
        data.notificationsByUserUid.map((notif) => {
            if (!notif.seen) notifyNumber++;
        });
    }

    const corpus = children ? (
        <div className={styles.navCorpus}>{children}</div>
    ) : null;

    return (
        <>
            {corpus}
            <div className={styles.navBarSpacer} />
            <div className={styles.navBar}>{buttons(path, messageCount)}</div>
        </>
    );
};
export default NavBar;
