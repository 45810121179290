// importing modules
import React, { useState, useEffect } from 'react';
import { useAuth } from 'context/firebase/authContext';
import { useHistory } from 'react-router-dom';
// importing components
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonIcon,
    IonRow,
    IonPage,
    IonSpinner,
    IonToast,
    useIonAlert,
} from '@ionic/react';
import UserImg from 'pages/RequestSkill/UserImg/UserImg';
import Strokes from 'components/Strokes';
import BackButton from 'components/BackButton';
import CancelButton from 'components/CancelButton/CancelButton';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { trashOutline } from 'ionicons/icons';
import {
    GetStripeCustomerAccount as GSCAQuery,
    DetachPaymentMethod as DPMMutation,
} from 'GraphQL/StripeConnect/StripeConnect';
// importing utilities
// importing assets
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
// importing styles
import '../Settings.css';
import './PaymentMethods.css';

export default () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<string>('');
    const [getStripeCustomerAccount, { data, refetch, loading }] =
        useLazyQuery(GSCAQuery);
    const [present] = useIonAlert();
    let stripeCustomerAccount;
    const [detachPaymentMethod, { data: detachData, error: detachError }] =
        useMutation(DPMMutation, {
            fetchPolicy: 'no-cache',
            onCompleted: (detachdata) => {
                if (detachData.detachPaymentMethod.status == 'success') {
                    refetch();
                    setShowToast(true);
                } else {
                    setErrorCode('7.09');
                }
            },
        });
    if (data) {
        stripeCustomerAccount = data.getStripeCustomerAccount;
    }
    useEffect(() => {
        if (stripeCustomerAccount) {
            refetch();
        } else {
            getStripeCustomerAccount({
                variables: {
                    fetchPolicy: 'no-cache',
                    uid: currentUser.uid,
                },
            });
        }
    }, [history.location.pathname, stripeCustomerAccount]);

    const redirectToAdd = () => {
        history.push('/settings/paymentmethods/add');
    };
    const removePaymentMethod = (paymentMethod: string) => {
        present({
            header: 'Removing Payment Method',
            message: 'Are you sure you want to remove this payment method?',
            buttons: [
                'Cancel',
                {
                    text: 'Yes',
                    handler: (d) =>
                        detachPaymentMethod({
                            variables: {
                                paymentMethodId: paymentMethod,
                            },
                        }),
                },
            ],
        });
    };
    return (
        <IonPage>
            <IonContent>
                <IonToast
                    cssClass='requestDetails__location--clipboard--toast'
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message='Payment Method Removed!'
                    duration={2000}
                />

                <IonGrid>
                    <IonRow className='settings__header'>
                        <IonCol
                            sizeSm='12'
                            sizeXs='12'
                            className='settings__header--container'
                        >
                            <Strokes top={true} />
                            <IonCol
                                sizeXs='12'
                                sizeSm='12'
                                sizeMd='12'
                                className='reqTaskNav__btns--wrap'
                            >
                                <BackButton color='white' />
                                <CancelButton />
                            </IonCol>
                            <UserImg providerUid={currentUser.uid} />
                        </IonCol>
                    </IonRow>
                    {/* title */}
                    <IonRow className='settings__textContent'>
                        <IonCol
                            className='settings__textContent--container'
                            sizeSm='12'
                            sizeXs='12'
                        >
                            <h1 className='settings__textContent--title'>
                                <span> Payment </span> Methods
                            </h1>
                        </IonCol>
                    </IonRow>
                    {/* Shows payment methods if they exist */}
                    {loading ? (
                        <div className='schedule__spinner--wrap'>
                            <IonSpinner
                                className='schedule__spinner'
                                name='crescent'
                            />
                        </div>
                    ) : (
                        <IonRow className='payment_body_container'>
                            {stripeCustomerAccount?.payment_methods?.length >
                            0 ? (
                                <IonRow className='settings__btn--container'>
                                    <IonGrid className='payment_methods_container'>
                                        <IonRow className='settings__form--row'>
                                            <IonRow className='payment_methods_sub_container'>
                                                {stripeCustomerAccount.payment_methods.map(
                                                    (method) => (
                                                        <IonCol
                                                            key={method.created}
                                                            className='settings__col payment_method_card'
                                                            sizeXs='12'
                                                            sizeSm='12'
                                                        >
                                                            <IonCol
                                                                sizeXs='12'
                                                                sizeSm='12'
                                                                className='paymentInfo__content'
                                                                style={{
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}
                                                            >
                                                                <div>
                                                                    <p className='settings__content--text'>
                                                                        {
                                                                            method
                                                                                .card
                                                                                .brand
                                                                        }
                                                                    </p>
                                                                    <p className='settings__content--description'>
                                                                        {
                                                                            method.type
                                                                        }
                                                                        {' ••••  ' +
                                                                            method
                                                                                .card
                                                                                .last4}
                                                                    </p>
                                                                </div>

                                                                <div>
                                                                    <IonIcon
                                                                        onClick={() =>
                                                                            removePaymentMethod(
                                                                                method.id
                                                                            )
                                                                        }
                                                                        className='delete_icon'
                                                                        icon={
                                                                            trashOutline
                                                                        }
                                                                    />
                                                                </div>
                                                            </IonCol>
                                                        </IonCol>
                                                    )
                                                )}
                                            </IonRow>
                                        </IonRow>
                                    </IonGrid>
                                </IonRow>
                            ) : (
                                <IonRow>
                                    <h5 className='no_payment_methods_heading'>
                                        {' '}
                                        No current payment methods
                                    </h5>
                                </IonRow>
                            )}
                            <ErrorMessage errorCode={errorCode} />
                            <IonRow className='settings__btn--container'>
                                <IonCol
                                    onClick={redirectToAdd}
                                    className='settings__btn--wrap'
                                    sizeXs='12'
                                    sizeSm='12'
                                >
                                    <IonButton
                                        fill='clear'
                                        color='ion-primary'
                                        className='settings__btn'
                                    >
                                        Add a Payment Method
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonRow>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};
